import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';

function Test( { location } ) {

  const { loginWithRedirect } = useAuth0();


  useEffect( () => {

    const params = new URLSearchParams( location.search );
    const email = params.get( 'email' );
    const to = params.get( 'to' );
    console.log( 'to',to );
    if( email ){
      loginWithRedirect( {
        login_hint: email,
        appState: {
          returnTo: '/' + to
        }
      } );
    }
  }, [location] );

  return(
    <>
    </>
  );
}

export default Test;
